<script lang="ts">
	import type { LoadResult } from '@kestrel/server';
	import type { load } from './ProductTeaserServer.ts';
	import { TeaserType } from '../../../domain/TeaserType.ts';
	import ToSignUp from '../../../components/teaser/large/ToSignUp.svelte';
	import ToLogin from '../../../components/teaser/large/ToLogin.svelte';

	export let data: LoadResult<typeof load>;
	const teaserSize = 'MediaObject100';

</script>

{#if !data.disableProductTeaser}
	{#if data.teaserType === TeaserType.NO_CONTENT || !data.teaserContent}
		<div data-testid="no-content-teaser-container" data-tracking-teaser-no-content-reason={data.noContentReason}></div>
	{:else if data.teaserType === TeaserType.TO_LOGIN}
		<ToLogin
			teaserContent={data.teaserContent}
			teaserSize={teaserSize}
		/>
	{:else}
		<ToSignUp
			teaserContent={data.teaserContent}
			teaserType={data.teaserType}
			enableSignupSheetMigration={data.enableSignupSheetMigration}
			teaserSize={teaserSize}
		/>
	{/if}
{/if}
